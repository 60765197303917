<template>
    <CCard>
        <task-edit-discussion-modal ref="discussionModal" :editor="editor" :contract-id="task?.contract?.id"/>
        <CardNavigation v-if="task?.id && !viewOnly" :task="task"/>

        <CCardBody class="p-0">
            <Transition name="fade-in">
                <page-content-loading-spinner v-if="!editor"/>
                <div v-else>
                    <div class="row">
                        <div class="col-8">
                            <editor-content-viewer :editor="editor" view-name="review-approve"/>
                        </div>
                        <div class="col-4 pl-0" style="max-height: 82vh; overflow-y: auto">
                            <review-approve-sidebar v-if="editor" :editor="editor" class="mr-3 mt-3"
                                                    :contract="task?.contract" :task="task"/>
                        </div>
                    </div>
                </div>
            </Transition>
        </CCardBody>
    </CCard>
    <div class="bubble-menu">
        <button v-if="contractCanApprove" ref="addCommentButton" class="btn btn-primary"
                @click.prevent="toggleDiscussionModal">
            Add comment
        </button>
    </div>
</template>

<script>
import changeSelectPage from '@/mixin/changeSelectPage'
import CardNavigation from '../components/CardNavigation'
import {initTaskReviewAndApproveEditorWithContent} from '@/utils/editor/editorFactory'
import TaskEditDiscussionModal from '@/components/Editor/TasksReviewAndApprove/DiscussionModal'
import {hideAll} from 'tippy.js'
import ReviewApproveSidebar from '@/views/Projects/Project/Tasks/Task/View/ReviewApprove/partials/ReviewApproveSidebar'
import EditorContentViewer from '@/components/Editor/EditorContentViewer'
import PageContentLoadingSpinner from '@/components/PageContentLoadingSpinner'
import {CLOSED_TASKS} from "@/domain/Entities/Task/taskStatuses";

export default {
    components: {
        PageContentLoadingSpinner,
        ReviewApproveSidebar,
        TaskEditDiscussionModal,
        CardNavigation,
        EditorContentViewer,
    },
    mixins: [changeSelectPage],
    props: {
        viewOnly: {type: Boolean, default: false}
    },
    data() {
        return {
            task: null,
            editor: null,
            contractCanApprove: null,
        }
    },
    async created() {
        this.$nextTick(async () => {
            await this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id)
            this.fetchData()
        })
    },
    methods: {
        toggleDiscussionModal() {
            let {from, to} = this.editor.view.state.selection
            const contentJSON = this.editor.view.state.selection
                .content()
                .content.toJSON()
            const contentText = this.editor.view.state.selection
                .content()
                .content.textBetween(
                    0,
                    this.editor.view.state.selection.content().content.size,
                )
            hideAll({duration: 200})
            this.$refs.discussionModal.show({
                range: {from, to},
                contentJSON,
                contentText,
            })
        },
        fetchData() {
            let {task_id} = this.$route.params
            if (task_id) {
                this.$http.tasks
                    .getTask({params: {task_id: task_id}})
                    .then((res) => {
                        this.task = res.data.data

                        if (CLOSED_TASKS.includes(this.task.status)) {
                            this.$router.push({
                                name: 'TaskContractsReviewDocument',
                                params: {
                                    id: this.$route.params.id,
                                    task_id: this.$route.params.task_id,
                                },
                            })
                        }

                        if (this.$store.getters.taskPermissions.reviewAndApproveEdit) {
                            this.contractCanApprove = res.data.data.contract.canCreateComment
                        }
                        this.$store.commit('GET_CONTRACT', this.task.contract)
                        this.editor = initTaskReviewAndApproveEditorWithContent({
                            html: res.data.data.contract.content,
                            task: this.task,
                        })
                        window.editor = this.editor
                    })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
:deep(.template-signature) {
    user-select: none;
    background-color: transparent !important;
}

.tasks-module-navigation {
    margin-bottom: 20px;
}
</style>
