<template>
    <CCardHeader>
        <Navigation :items="linksList"/>
    </CCardHeader>
</template>

<script>
import {PENDING_EXTERNAL_SIGNATURE, PENDING_INTERNAL_SIGNATURE} from "@/domain/Entities/Task/taskStatuses";
import Navigation from '@/components/Card/Navigation'
import $P from '@/utils/constants/permissions'
import {mapGetters} from 'vuex'

export default {
    name: 'CardNavigation',
    components: {Navigation},
    props: {
        task: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters({
            permissions: 'taskPermissions',
        }),
        isPendingStatus() {
            return [
                PENDING_EXTERNAL_SIGNATURE,
                PENDING_INTERNAL_SIGNATURE,
            ].includes(this.task?.status)
        },
        isExternal() {
            return this.task && this.task.is_external
        },
        linksList() {
            if (!this?.task?.id) return []

            return [
                {
                    name: 'Task Details',
                    permissions: [$P.TASKS_AND_DOCUMENTS_VIEW],
                    route: {
                        name: 'TaskContracts',
                        params: {
                            id: this.$route.params.id,
                            task_id: this.$route.params.task_id,
                        },
                    },
                },
                {
                    name: 'Review & Approve',
                    permissions: [$P.TASKS_AND_DOCUMENTS_VIEW],
                    disabled: !this.permissions.reviewAndApproveView,
                    route: {
                        name: 'TaskReviewApprove',
                        params: {
                            id: this.$route.params.id,
                            task_id: this.$route.params.task_id,
                        },
                    },
                },
                {
                    name: 'Send for Signature',
                    hidden: this.isExternal,
                    disabled: !this.permissions.sendForSignatureView,
                    route: {name: 'TaskSignature'},
                },
                {
                    name: 'Sign',
                    disabled: !this.isPendingStatus,
                    route: {
                        name: 'TaskSign',
                        params: {
                            id: this.$route.params.id,
                            task_id: this.$route.params.task_id,
                        },
                    },
                },
            ]
        },
    },
    watch: {
        task: {
            async handler(val) {
                await this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id)
            },
            deep: true
        }
    },
    async created() {
        await this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id)
    },
}
</script>

<style scoped></style>
